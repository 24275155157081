export class Emitter {
  constructor() {
    const delegate = document.createDocumentFragment();
    this.addEventListener = delegate.addEventListener.bind(delegate);
    this.removeEventListener = delegate.removeEventListener.bind(delegate);
    this.dispatchEvent = delegate.dispatchEvent.bind(delegate);
  }
}

// PolyFill for new Event in IE
(function _customEventPolyfill() {
  function CustomEvent(event, params) {
    // eslint-disable-next-line no-param-reassign
    params = params || {bubbles: false, cancelable: false, detail: undefined};
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }
  // If not IE
  if (typeof window.CustomEvent !== 'function') {
    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
  }
})();
